<template>
	<div id="home">
		<v-head currentIndex=0></v-head>
        <swiper ref="mySwiper" :options="swiperOption" style="width: 100%; overflow-x: hidden;position: relative;">
            <swiper-slide  v-for="slide of [1,2,3]" :key="slide.id">
                <div :class='{"header-content-container": true, "img": true, "show": slide==1}'>
                    <router-link to="/job/list" class="btn-show-job">
                        <img src="../../../assets/imgs/pc/img_qinataibanner.webp" alt="">
                    </router-link>
                    <div class="header-content">
                    </div>
                </div>
                <div :class='{"header-content-container": true, "show": slide==2}'>
                    <div class="header-content">
                        <h2 class="header-title">千位中醫師免費回復您的健康提問</h2>
                        <p class="header-desc">100%回復，咨询團隊均有行醫資格認證</p>
                        <router-link to="/os/doctor_signup" class="btn-os">
                            <span class="hint">免費申請診所賬號</span>
                        </router-link>
                    </div>
                </div>
                <div :class='{"header-content-container": true, "img": true, "show": slide==3}'>
                    <img src="../../../assets/imgs/pc/img_bannertwo.webp" alt="">
                    <div class="header-content">
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="aq-head-wrapper">
            <div class="job-list-wrapper" v-if="jobList && jobList.length">
                <h2 class="title">診所在找職位</h2>
                <div class="job-list">
                    <router-link :to="'job/details/'+jobList[jobIndex].jobid" class="job-item">
                        <div class="job-name">{{jobList[jobIndex].title}}（{{ jobList[jobIndex].number }} 名）</div>
                        <div class="job-clinic">診所：{{jobList[jobIndex].company}}</div>
                        <div class="job-intro">{{jobList[jobIndex].jobdesc}}</div>
                    </router-link>
                </div>
                <div class="operate">
                    <router-link to="/job/list" class="btn-link">全部招聘信息</router-link>
                    <div class="dots">
                        <div @click="jobIndex = index":class="{'dot': true, 'current': jobIndex==index}" v-for="(item, index) in jobList" :key="item.jobId"></div>
                    </div>
                </div>

            </div>
            <ul class="aq-head">
                <li :class="{'aq-head-tab': true, 'current': tabIndex == 0}"><router-link to="/">健康問答</router-link></li>
                <li v-if="userInfo" :class="{'aq-head-tab': true, 'current': tabIndex == 1}"><router-link to="/my-ask">我的問答</router-link><span class="my-ak-count">3</span></li>
                <!-- <li v-if="userInfo" :class="{'aq-head-tab': true, 'current': tabIndex == 2}"><router-link to="/my-saved">我的收藏</router-link></li> -->
            </ul>
        </div>
        <div class="center-box">
            <router-view class="router-view"></router-view>
            <div class="app-info-box">
                <div :class="{'booking-count': true, 'max': jobList && jobList.length}">
                    <div class="booking-count-content">
                        <div class="row row-1">
                            <div class="count">1</div>
                            <div class="hint">已預約人次</div>
                            <div class="value" v-text="(bookingCount && bookingCount.ordercount && bookingCount.ordercount.toLocaleString()) || ''"></div>
                        </div>
                        <div class="row row-2">
                            <div class="count">2</div>
                            <div class="hint">顧客人數</div>
                            <div class="value" v-text="(bookingCount && bookingCount.clientcount && bookingCount.clientcount.toLocaleString()) || ''"></div>
                        </div>
                        <div class="row row-3">
                            <div class="count">3</div>
                            <div class="hint">已發布職位空缺</div>
                            <div class="value" v-text="(bookingCount && bookingCount.validJobCount && bookingCount.validJobCount.toLocaleString()) || ''"></div>
                        </div>
                    </div>
                    <img class="arrow-top" src="../../../assets/imgs/pc/img_sanjiao@2x.png">
                </div>
                <div class="app-intro">
                    <img src="../../../assets/imgs/pc/img_dayilogo@2x.png" alt="大醫App" class="icon-app">
                    <h3 class="app-name">大醫APP</h3>
                    <span class="app-from">由中醫在綫開發推出</span>
                    <p class="app-desc">大醫APP是一款專注于智慧健康、疾病防禦、健康管理為理念，致力於提供“互動、便捷、全面”的健康管家。</p>
                </div>
                <div class="app-modules">
                    <div class="app-module app-module-1">
                        <i class="icon"></i>
                        <span class="app-module-title">【診症預約】</span>
                        <div class="app-module-desc">便捷的線上選擇搜索醫師進行預約掛號</div>
                    </div>
                    <div class="app-module app-module-2">
                        <i class="icon"></i>
                        <span class="app-module-title">【檢查記錄】</span>
                        <div class="app-module-desc">添加/查詢健康記錄、查詢就診記錄、服務卡和療程消費情況</div>
                    </div>
                    <div class="app-module app-module-3">
                        <i class="icon"></i>
                        <span class="app-module-title">【用藥提醒】</span>
                        <div class="app-module-desc">設定吃藥時間，到點會有鈴聲提醒，忘吃藥從此一去不返</div>
                    </div>
                    <div class="app-module app-module-4">
                        <i class="icon"></i>
                        <span class="app-module-title">【月經記錄】</span>
                        <div class="app-module-desc">針對女性用戶的月經功能，記錄月經預測往後月經日期，排卵期等；根據月經記錄分析相關月經病及異常，給與相應健康建議</div>
                    </div>
                    <!-- <div class="app-module app-module-5 clearfix">
                        <div class="bg">
                            <i class="icon pet"></i>
                            <span class="app-module-title">貼心寵物</span>
                            <div class="app-module-desc">可以與你互動的健康小助手</div>
                        </div>
                    </div> -->
                </div>
                <div class="app-qrcode-container">
                    <div class="app-qrcode ios">
                        <img src="../../../assets/imgs/qr_code.png" alt="">
                        <span class="qrcode-title">掃碼下載大醫App</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="content page2" v-if="newsList.length">
            <div class="article-container">
                <h3 class="article-head">健康資訊</h3>
                <div class="article-list-warpper">
                    <div class="article-list">
                        <a-carousel class="article-carousel" :beforeChange="onArticlePageChanged" autoplay>
                            <div class="article-items current" v-for="(newsDatas, i) in newsList" :key="i">
                                <router-link :to="'/news/detail/'+news.newsid" class="article-item" v-for="(news, index) in newsDatas" :key="index">
                                    <span class="img-cover"></span>
                                    <h4 class="article-item-title" v-text="news.title"></h4>
                                    <p class="article-item-tags" v-text="news.tag.split(/\||,|，/).filter(t=>!!t).map(t=>'#'+t).join('')"></p>
                                    <p class="article-item-content" v-html="news && news.content && news.content.replace(/<img/g, '<span')"></p>
                                    <div class="article-item-author">
                                        <span class="icon-gender man"></span>
                                        <div class="r">
                                            <span class="author-name" v-text="news.creator"></span>
                                            <span class="create-time" v-text="news.createdtime"></span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </a-carousel>
                    </div>
                </div>
            </div>
        </div>
        <div class="content page3">
            <div class="manager-system">
                <video id="osvideo" width="410" height="230" controls="" autoplay="autoplay">
                    <source src="https://ve.media.tumblr.com/tumblr_o0h1c72s4O1v25v5a_480.mp4" type="video/mp4">
                </video>
                <div class="manager-system-r">
                    <h3 class="m-title">中醫在綫-現代化中醫診所管理系統</h3>
                    <p class="m-desc">符合中醫藥管理委員會最新要求</p>
                    <div class="m-operate">
                        <router-link to="/os/doctor_signup" class="btn-m-account">免費申請診所管理系統賬號</router-link>
                        <a href="https://os.ectcm.com/OnlineBook/" class="btn-m-more" target="_blank">了解更多</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="content" v-if="clinicList.length">
            <div class="clinic-container">
                <h3 class="clinic-header">使用中醫在綫的診所</h3>
                <div class="clinic-wrapper">
                    <i class="arrow l" @click="toPrevClinicPage"></i>
                    <div class="clinic-list">
                        <a-carousel ref="clinicCarousel" class="clinic-carousel" autoplay>
                            <div class="clinic-items current" v-for="(clinicDatas, i) in clinicList" :key="i">
                                <router-link :to="'/doctor/list?ClinicID='+clinic.clinicid" class="clinic-item" v-for="(clinic, index) in clinicDatas" :key="index">
                                    <img :src="clinic.logo" alt="">
                                    <div class="clinic-item-content">
                                        <div class="clinic-name" v-text="clinic.clinicname"></div>
                                        <div class="clinic-address" v-text="clinic.arealevel1name + '>' + clinic.arealevel2name"></div>
                                    </div>
                                </router-link>
                            </div>
                        </a-carousel>
                    </div>
                    <i class="arrow r" @click="toNextClinicPage"></i>
                </div>
            </div>
        </div>
		<v-foot></v-foot>
	</div>
</template>

<script>
	import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
    import job_list from '@/views/mixins/job_list'
	export default {
		components: {
			vHead,
			vFoot
        },
        mixins:[
            job_list,
        ],
        data(){
            return {
                tabIndex: 0,
                jobList: [],
                jobIndex: 0,
                timer: undefined,
                bookingCount: null,
                userInfo: this.$store.getters.userInfo,
                newsList: this.$store.getters.homeNewsList || [],
                clinicList: this.$store.getters.homeClinicList || [],
                swiperOption: {
                    loop: true,
                    speed: 800,
                    delay: 3000,
                    autoplay: true,
                    autoplay: {
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    mousewheelControl: true,
                }
            }
        },
        mounted: function(){
            var path = this.$route.path
            if(path.indexOf('/my-ask') != -1) {
                if(this.userInfo) {
                    this.tabIndex = 1
                } else {
                    window.location.href = '/'
                }
            } else if(path.indexOf('/my-saved') != -1) {
                if(this.userInfo) {
                    this.tabIndex = 2
                } else {
                    window.location.href = '/'
                }
            } else {
                this.tabIndex = 0
                if(!this.newsList.length || !this.clinicList.length) {
                    this.loadServerData()
                }
            }
            this.getBookingCount()
            this.getJobList()
        },
        methods: {
            getJobList: function(){
                const params = {
                    pageNum: 1,
                    pageSize: 4,
                }
                this.loadData(params, (data, error)=>{
                    if(!error) {
                        this.jobIndex = 0
                        this.jobList = data.list
                        if(this.timer) {
                            clearInterval(this.timer)
                            this.timer = null
                        }
                        if(this.jobList && this.jobList.length) {
                            this.timer = setInterval(() => {
                                this.jobIndex ++
                                if(this.jobIndex >= this.jobList.length) {
                                    this.jobIndex = 0
                                }
                            }, 5000);
                        }
                    }
                })
            },
            loadServerData: function(){
                //加载15条资讯
                this.$store.dispatch('getNewsList', {pageNo: 1, pageSize: 15})
                .then((rs) => {
                    var newsList = (rs && rs.news && rs.news.list) || []
                    newsList = newsList.length == 15 ? newsList : []
                    var list = []
                    var stringToDate = (_date,_format,_delimiter) => {
                        var formatLowerCase=_format.toLowerCase();
                        var formatItems=formatLowerCase.split(_delimiter);
                        var dateItems=_date.split(_delimiter);
                        var monthIndex=formatItems.indexOf("mm");
                        var dayIndex=formatItems.indexOf("dd");
                        var yearIndex=formatItems.indexOf("yyyy");
                        var month=parseInt(dateItems[monthIndex]);
                        month-=1;
                        var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
                        return formatedDate;
                    }

                    for(var i=0; i<newsList.length;i++) {
                        if(i%3 == 0) {
                           list.push([])
                        }
                        var item = newsList[i]
                        if(item.createdtime) {
                            var d = item.createdtime.substring(0, 10)
                            var date = stringToDate(d, 'dd/MM/yyyy', '/');
                            var year = date.getFullYear()
                            var month = date.getMonth()+1
                            var day = date.getDate()
                            var dateStr = `${year}-${month<10 ? ('0'+month) : month}-${day<10 ? ('0'+day) : day}`;
                            item.createdtime = dateStr
                        }
                        list[list.length -1].push(item)
                    }
                    this.newsList = list
                    this.$store.dispatch('getHomeClinics', {pageNo: 1, pageSize: 15})
                    .then((_rs) => {
                        var clinicList = (_rs && _rs.list) || []
                        clinicList = clinicList.length == 15 ? clinicList : []
                        var _list = []
                        for(var i=0; i<clinicList.length;i++) {
                            if(i%3 == 0) {
                                _list.push([])
                            }
                            _list[_list.length -1].push(clinicList[i])
                        }
                        this.clinicList = _list
                    })
                })
            },
            onArticlePageChanged: function(from, to) {
            },
            toPrevClinicPage: function() {
                this.$refs.clinicCarousel.prev()
            },
            toNextClinicPage: function() {
                this.$refs.clinicCarousel.next()
            },
            getBookingCount: function() {
                this.$store.dispatch('getBookingCount', null)
                .then((data)=>{
                    this.bookingCount = data
                })
            }
        },
        watch: {
            $route(to,from){
				if(to.path.indexOf('/my-ask') != -1) {
                    if(this.userInfo) {
                        this.tabIndex = 1
                    } else {
                        window.location.href = '/'
                    }
                } else if(to.path.indexOf('/my-saved') != -1) {
                    if(this.userInfo) {
                        this.tabIndex = 2
                    } else {
                        window.location.href = '/'
                    }
                } else {
                    this.tabIndex = 0
                }
			}
        }
	};
</script>

<style lang="scss" scoped>
    .swiper-pagination{
        bottom: 40px;
    }
    .article-carousel /deep/ .slick-slide {
        text-align: center;
        height: 440px;
        background: #fff;
        overflow: hidden;
    }

    .clinic-carousel  /deep/ .slick-slide {
        text-align: center;
        height: 115px;
        overflow: hidden;
    }

    .article-carousel /deep/ .slick-dots {
        margin-bottom: 46px;
    }

    .article-carousel /deep/ .slick-dots li > button {
        width: 15px;
        height: 16px;
        background-color: #fff;
        box-shadow: 0px 2px 6px #71e5f0;
        margin: 0px 17px;
        cursor: pointer;
        border-radius: 7px;
        opacity: 1;
    }

    .article-carousel /deep/ .slick-dots li.slick-active button {
        opacity: 1;
        background-color: #21d3e4;
    }
    
    #home {
        width: 100%;
        position: relative;
    }

    .header-content-container {
        width: 100%;
        height: 252px;
        display: none;
        background-image: url(../../../assets/imgs/pc/img_bannerbg@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 24px;
        overflow: hidden;
    }

    .header-content-container.job {
        background-image: url(../../../assets/imgs/pc/img_zpbeij@2x.png);
    }

    .header-content-container.img {
        background-image: unset;
        background-color: #21d3e4;
        display: none;
    }

    .header-content-container.img  img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .header-content-container.show {
        display: block;
    }
    .header-content {
        width: 1000px;
        height: 252px;
        margin: 0px auto;
        position: relative;
    }

    .header-content .btn-os {
        position: absolute;
        left: 0;
        bottom: 49px;
        height: 36px;
        width: 185px;
        line-height: 36px;
        text-align: center;
        color: #FF7800;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        background-color: #FF7800;
    }

    .header-content .btn-show-job {
        background: #FFFFFF;
        border-radius: 0px 13px 13px 13px;
        color: #3A87EF;
        padding: 6px 15px;
        font-weight: bold;
        cursor: pointer;
        background-color: #fff;
        bottom: 60px;
        position: absolute;
    }

    .header-content .btn-os > span {
        position: absolute;
        background-color: #FFE6AB;
        left: 4px;
        top: 4px;
        height: 36px;
        right: -4px;
        bottom: -4px;
    }

    .header-content > .header-title {
        padding-top: 57px;
        font-size: 36px;
        font-weight: bold;
        text-align: left;
        color: #231F20;
        line-height: 35px;
    }

    .header-content > .header-title > img {
        object-fit: contain;
        height: 39px;
    }

    .header-content > .header-desc {
        margin-top: 18px;
        margin-bottom: 64px;
        font-size: 20px;
        line-height: 20px;
        font-weight: 400;
        text-align: left;
        color: #231f20;
    }
    .header-content > .header-desc > img {
        object-fit: contain;
        height: 25px;
    }

    .job-list-wrapper {
        width: 692px;
        height: 200px;
        background-color: #fff;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        user-select: none;
    }

    .job-list-wrapper .title {
        position: relative;
        font-size: 19px;
        font-weight: bold;
        color: #040415;
        width: 140px;
        padding-right: 26px;
        margin: 21.5px 0px 0px 18px;
    }

    .job-list-wrapper .title::after {
        content: '';
        position: absolute;
        right: 0px;
        width: 18px;
        height: 22.5px;
        background-image: url(../../../assets/imgs/pc/img_hot@2x.png);
        background-size: 100% 100%;
    }

    .job-list-wrapper .title::before {
        content: '';
        position: absolute;
        bottom: -15px;
        width: 30px;
        height: 4px;
        background-color: #DCF7FB;
    }

    .job-list-wrapper .job-list {
        height: 98px;
        margin: 24px 16px 0px 16px;
        font-size: 12px;
        color: #040415;
    }

    .job-list-wrapper .job-list .job-name {
        font-weight: bold;
    }

    .job-list-wrapper .job-list .job-clinic {
        color: #999;
        margin-top: 8px;
    }

    .job-list-wrapper .job-list .job-intro {
        font-size: 10px;
        color: #A7A7A7;
        margin-top: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .job-list-wrapper .operate {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 18px;
    }

    .job-list-wrapper .operate .btn-link {
        height: 20px;
        line-height: 20px;
        box-sizing: border-box;
        background-color: #FBEFE3;
        padding: 0px 8px;
        border-radius: 4px;
        font-size: 9px;
        color: #FF8000;
        margin-left: 18px;
    }

    .job-list-wrapper .operate .dots {
        display: flex;
        flex-direction: row;
        margin-right: 18px;
    }

    .job-list-wrapper .operate .dots .dot {
        width: 13px;
        height: 3px;
        margin-right: 7px;
        background-color: #EFEFEF;
        cursor: pointer;
    }

    .job-list-wrapper .operate .dots .dot.current {
        width: 21.5px;
        background-color: #36C4D0;
    }

    .aq-head-wrapper {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

	.aq-head {
		width: 692px;
        height: 60px;
        background-color: #C3EDF2;
        line-height: 60px;
        margin-bottom: 1px;
    }

    .aq-head > .aq-head-tab {
        float: left;
        padding: 0px 10px;
        margin-right: 30px;
        cursor: pointer;
        position: relative;
        color: #231F20;
        font-size: 18px;
        font-weight: 400;
    }

    .aq-head > .aq-head-tab.mobile-tab {
        display: none;
    }

    .aq-head > .aq-head-tab.current {
        font-weight: bold;
        color: #36C4D0;
    }

    .aq-head > .aq-head-tab .aq-tab1:target {
        background-color: #969696;
    }

    .aq-head > .aq-head-tab > .my-ak-count {
        position: absolute;
        right: 0;
        width: 18px;
        top: 13px;
        height: 18px;
        border-radius: 50%;
        background-color: #EE1B23;
        color: #fff;
        line-height: 18px;
        text-align: center;   
        font-size: 14px;
        font-weight: 400;

        display: none;
    }

    .center-box {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .center-box > .router-view {
        width: 692px;
        background-color: #fff;
    }

    .center-box > .app-info-box {
        width: 298px;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
    }

    /* 大医app start */

    .app-intro {
        padding: 20px;
        background-color: #FFF4EF;
        border-bottom: 1px solid #FFC7AF;
    }

    .app-intro .icon-app {
        float: left;
        width: 58px;
        height: 58px;
    }

    .app-intro .app-name {
        float: left;
        color: #231F20;
        font-size: 24px;
        font-weight: bold;
        line-height: 40px;
        margin-left: 18px;
    }

    .app-intro .app-from {
        float: left;
        font-size: 14px;
        font-weight: 400;
        color: #231F20;
        line-height: 19px;
        margin-left: 19px;
    }

    .app-intro .app-desc {
        clear: both;
        margin-top: 88px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }

    .app-modules {
        background-color: #fff;
        padding-bottom: 22px;
    }

    .app-modules > .app-module {
        padding: 27px 20px 0px 19px;
        clear: both;

    }

    .app-modules > .app-module .icon {
        float: left;
        width: 46px;
        height: 46px;
        background-image: url(../../../assets/imgs/pc/img_yuye@2x.png);
        background-size: 100% 100%; 
        background-repeat: no-repeat;  
    }

    .app-modules > .app-module:nth-child(2) .icon {
        background-image: url(../../../assets/imgs/pc/img_jilu@2x.png);
    }

    .app-modules > .app-module:nth-child(3) .icon {
        background-image: url(../../../assets/imgs/pc/img_tixing@2x.png);
    }

    .app-modules > .app-module:nth-child(4) .icon {
        background-image: url(../../../assets/imgs/pc/img_yuejing@2x.png);
    }

    .app-modules > .app-module:nth-child(5) .icon {
        background-image: url(../../../assets/imgs/pc/img_chongwu@2x.png);
        width: 55px;
        height: 48px;
    }

    .app-modules > .app-module > .app-module-title {
        float: left;
        margin-left: 18px;
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
        line-height: 16px;
    }


    .app-modules > .app-module:nth-child(5) > .bg {
        padding-left: 8px;
        padding-top: 11px;
        height: 68px;
        background-color: #FFDDCD;
    }

    .app-modules > .app-module:nth-child(5) .app-module-title {
        margin-left: 8px;
        font-size: 16px;
        font-weight: bold;
        color: #BE3D00;
    }

    .app-modules > .app-module .app-module-desc {
        float: left;
        margin-left: 26px;
        font-size: 14px;
        font-weight: 400;
        width: 187px;
        color: #969696;
        line-height: 23px;
        margin-top: 10px;
    }

    .app-modules > .app-module:nth-child(5) .app-module-desc {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #5F5557;
    }

    .app-qrcode-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: 30px;
        width: 258px;
        margin-left: 30px;
    }

    .app-qrcode {
        display: inline-block;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .app-qrcode > img {
        display: inline-block;
        width: 114px;
        height: 114px;
    }

    .app-qrcode > .qrcode-title {
        display: inline-block;
        line-height: 30px;
        font-size: 14px;
        color: #231F20;
        font-weight: 400;
        white-space: nowrap;
    }

    .link-app {
        line-height: 34px;
        margin-left: 31px;
    }

    .link-app.ios {
        margin-top: 20px;
    }

    .link-app > .icon {
        display: inline-block;
        background-image: url(../../../assets/imgs/pc/img_xiaoren@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 17px;
        height: 17px;
        vertical-align: middle;
        margin-bottom: 5px;
    }

    .link-app.ios > .icon {
        width: 16px;
        height: 18px;
        background-image: url(../../../assets/imgs/pc/img_pingguo@2x.png);
    }

    .link-app > a {
        font-size: 16px;
        color: #043A3D;
        font-weight: 400;
        margin-left: 8px;
        text-decoration: underline;
    }
    /* 大医app end */


    /* 管理系统简介 start */
    .manager-system {
        width: 1000px;
        margin: 0px auto;
        height: 330px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .manager-system > .manager-system-r {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 79px 0px 0px 51px;
        align-self: flex-start;
    }

    .manager-system > .manager-system-r > .m-title {
        font-weight: bold;
        line-height: 28px;
        font-size: 28px;
        color: #231F20;
    }

    .manager-system > .manager-system-r > .m-desc {
        margin-top: 22px;
        line-height: 20px;
        font-size: 20px;
        color: #636363;
        font-weight: 400;
    }

    .manager-system > .manager-system-r > .m-operate {
        margin-top: 75px;
        display: flex;
        flex-direction: row;
    }

    .manager-system > .manager-system-r > .m-operate > .btn-m-account {
        height: 36px;
        line-height: 36px;
        position: relative;
        padding: 0px 10px 0px 30px;
        background-color: #FFDEBD;
        border-radius: 8px;
        font-size: 16px;
        color: #72240B;
        font-weight: 400;
    }

    .manager-system > .manager-system-r > .m-operate > .btn-m-account::before {
        position: absolute;
        content: '';
        left: 10px;
        top: 11px;
        width: 16px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_zhangjao@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .manager-system > .manager-system-r > .m-operate > .btn-m-more {
        height: 36px;
        line-height: 36px;
        position: relative;
        padding: 0px 16px 0px 38px;
        background-color: #7AE1ED;
        border-radius: 8px;
        font-size: 16px;
        color: #043A3D;
        font-weight: 400;
        margin-left: 30px;
    }

    .manager-system > .manager-system-r > .m-operate > .btn-m-more::before {
        position: absolute;
        content: '';
        left: 12px;
        top: 11px;
        width: 17px;
        height: 14px;
        background-image: url(../../../assets/imgs/pc/img_manager_more@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    /* 管理系统简介 end */

    /* 诊所列表 start */
    .content {
        height: 256px;
        background-color: #fff;
    }

    .clinic-container {
        width: 1000px;
        margin: 0px auto;
    }

    .clinic-container .clinic-header {
        position: relative;
        height: 123px;
        line-height: 123px;
        text-align: center;
        font-size: 24px;
        font-weight: 300;
        color: #231F20;
    }

    .clinic-container .clinic-header::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 158px;
        height: 8px;
        background-image: url(../../../assets/imgs/pc/img_rightzs@2x.png);
        transform: translate(161px, -50%);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .clinic-container .clinic-header::after {
        content: '';
        position: absolute;
        right: 50%;
        top: 50%;
        width: 158px;
        height: 8px;
        transform: translate(-161px, -50%);
        background-image: url(../../../assets/imgs/pc/img_leftzs@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .clinic-container .clinic-wrapper {
        width: 1000px;
        display: flex;
        height: 64px;
        margin-top: 18px;
        flex-direction: row;
    }

    .clinic-container .clinic-wrapper > .arrow {
        display: inline-block;
        width: 14px;
        margin-top: 6px;
        height: 52px;
        background-image: url(../../../assets/imgs/pc/img_left@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }

    .clinic-container .clinic-wrapper > .arrow.r {
        background-image: url(../../../assets/imgs/pc/img_right@2x.png);
    }

    .clinic-container .clinic-wrapper .clinic-list { 
        flex: 1;
        margin-left: 30px;
        margin-right: 30px;
        position: relative;
        overflow: hidden;
    }

    .clinic-container .clinic-wrapper .clinic-list .clinic-items {
        width: 912px;
        height: 64px;
        display: flex !important;
        flex-direction: row;
    }

    .clinic-container .clinic-wrapper .clinic-list .clinic-items > .clinic-item {
        display: flex;
        flex-direction: row;
        width: 284px;
        margin-right: 20px;
        cursor: pointer;
    }

    .clinic-container .clinic-wrapper .clinic-list .clinic-items > .clinic-item > img {
        display: inline-block;
        width: 74px;
        height: 64px;
        margin-right: 10px;
        position: relative;
    }

    .clinic-container .clinic-wrapper .clinic-list .clinic-items > .clinic-item > img[src='']::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 74px;
        height: 64px;
        background-image: url(../../../assets/imgs/pc/img_moren@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #fff;
    }

    .clinic-container .clinic-wrapper .clinic-list .clinic-items > .clinic-item .clinic-item-content {
        flex: 1;
        width: 200px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .clinic-container .clinic-wrapper .clinic-list .clinic-items > .clinic-item .clinic-name {
        font-size: 18px;
        line-height: 18px;
        font-weight: 400;
        color: #231F20;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        display: inline-block;
        -webkit-box-orient: vertical;
        white-space: nowrap;
    }

    .clinic-container .clinic-wrapper .clinic-list .clinic-items > .clinic-item .clinic-address {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #36C4D0;
        display: inline-block;
        margin-top: 13px;
    }
    /* 诊所列表 end */


    /* 健康资讯 start */
    .content.page2 {
        height: 573px;
        margin-top: 50px;
        background-color: #fff;
    }

    .content.page3 {
        height: 330px;
        background-color: transparent;
    }
    
    .article-container .article-head {
        height: 133px;
        line-height: 133px;
        position: relative;
        font-size: 24px;
        font-weight: 300;
        color: #231F20;
        text-align: center;
    }

    .article-container .article-head::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 158px;
        height: 8px;
        transform: translate(101px, -50%);
        background-image: url(../../../assets/imgs/pc/img_rightzs@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .article-container .article-head::after {
        content: '';
        position: absolute;
        right: 50%;
        top: 50%;
        width: 158px;
        height: 8px;
        transform: translate(-101px, -50%);
        background-image: url(../../../assets/imgs/pc/img_leftzs@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .article-list-warpper {
        width: 1000px;
        margin: 0px auto;
    }

    .article-list {
        width: 100%;
        height: 573px;
        position: relative;
        overflow-x: hidden;
    }

    .article-items {
        width: 1000px;
        height: 338px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .article-item {
        width: 284px;
        height: 338px;
        position: relative;
        background-color: white;
        display: inline-block;
        margin-right: 74px;
    }

    .article-item:nth-child(3){
        margin-right: 0px;
    }

    .article-item > .article-item-title {
        position: absolute;
        left: 14px;
        right: 14px;
        top: 109px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        line-height: 18px;
        text-align: left;
    }

    .article-item > .article-item-tags {
        position: absolute;
        left: 14px;
        right: 14px;
        top: 135px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        line-height: 13px;
    }

    .article-item > .article-item-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        height: 63px;
        color: #636363;
        position: absolute;
        text-align: left;
        left: 12px;
        right: 12px;
        top: 184px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .article-item > .article-item-author {
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 24px;
        justify-content: flex-start;
    }

    .article-item > .article-item-author > .icon-gender {
        width: 36px;
        height: 36px;
        margin-right: 11px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .article-item > .article-item-author > .r {
        display: flex;
        flex-direction: column;
    }

    .article-item > .article-item-author .author-name {
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
        line-height: 16px;
        text-align: left;
    }

    .article-item > .article-item-author .create-time {
        font-size: 14px;
        font-weight: 400;
        color: #969696;
        line-height: 14px;
        margin-top: 8px;
    }

    .article-item > .article-item-author > .icon-gender.man {
        background-image: url(../../../assets/imgs/pc/img_nanwenz@2x.png);
    }

    .article-item > .article-item-author > .icon-gender.woman {
        background-image: url(../../../assets/imgs/pc/img_nvzy@2x.png);
    }

    .article-item > img {
        width: 284px;
        height: 162px;
    }

    .article-item > .img-cover {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 162px;
        background-color: rgba(22, 12, 2, 0.5)
    }

    .article-item:hover > img {
        width: 284px;
        height: 338px;

    }

    .article-item:hover > .img-cover {
        height: auto;
        bottom: 0;
        background-color: rgba(4, 58, 61, 0.85)
    }
    .article-item:hover > .article-item-title {
        top: 77px;
        text-align: center;
    }

    .article-item:hover > .article-item-tags {
        top: 108px;
        text-align: center;
    }

    .article-item:hover > .article-item-content {
        top: 154px;
        color: #fff;
    }

    .article-item:hover > .article-item-author {
        justify-content: center;
    }

    .article-item:hover > .article-item-author .create-time,
    .article-item:hover > .article-item-author .author-name {
        color: #fff;    
    }

    .article-dots {
        width: 160px;
        margin: 40px auto 0px auto;
        display: flex;
        flex-direction: row;
    }

    .article-dots > .article-dot {
        width: 15px;
        height: 16px;
        background-color: #fff;
        box-shadow: 0px 2px 6px #71e5f0;
        margin: 0px 17px;
        cursor: pointer;
        border-radius: 7px;
    }

    .article-dots > .article-dot.current {
        background-color: #21d3e4;
    }

    /* 健康资讯 end */

    .booking-count {
        background-color: #C2EDF1;
        display: flex;
        margin-top: -120px;
        flex-direction: column;
        margin-bottom: 10px;
        position: relative;
        user-select: none;
        overflow: hidden;
    }

    .booking-count.max {
        margin-top: -330px;
    }
    
    .booking-count .arrow-top {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
        width: 30.5px;
        height: 28px;
    }

    .booking-count > .booking-count-content {
        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 24px;
        color: #231F20;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        padding-top: 23px;
    }

    .booking-count > .booking-count-content .count {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        text-align: center;
        line-height: 18px;
        color: #AAAAAA;
        font-size: 12px;
        font-weight: 400;
        background-color: #ECECEC;
        margin-right: 12px;
    }
    
    .booking-count > .booking-count-content > .row {
        display: flex;
        flex-direction: row;
        margin-bottom: 22px;
        align-items: center;
        width: 100%;
    }

    .booking-count > .booking-count-content > .row .hint,
    .booking-count > .booking-count-content > .row .value {
        color: #040415;
        font-size: 15px;
        font-weight: 400;
        font-style: italic;
    }
    .booking-count > .booking-count-content > .row .hint {
        flex: 1;
    }

    .booking-count > .booking-count-content > .row .value {
        width: 100px;
        color: #040415;
        font-weight: bold;
    }

    .booking-count > .booking-count-content > .row.row-1 .count {
        background-color: #040415;
        color: #36C4D0;
    }

    .booking-count > .booking-count-content > .row.row-2 .count {
        background-color: #040415;
        color: #FF8000;
    }

    .booking-count > .booking-count-content > .row.row-1 .hint,
    .booking-count > .booking-count-content > .row.row-2 .hint {
        font-weight: bold;
        position: relative;
        z-index: 1;
    }

    .booking-count > .booking-count-content > .row.row-1 .hint::before,
    .booking-count > .booking-count-content > .row.row-2 .hint::before {
        content: '';
        position: absolute;
        width: 28px;
        height: 6px;
        background-color: #36C4D0;
        border-radius: 3px;
        bottom: 2px;
        z-index: -1;
    }

    .booking-count > .booking-count-content > .row.row-2 .hint::before {
        background-color: #FF8000;
    }
    
    .booking-count > .booking-count-content > .row.row-3 .value {
        color: #36C4D0;
    }

    .booking-count > .booking-count-content > .row.row-4 .value {
        color: #FF8000;
    }
</style>
